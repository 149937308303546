.mt30 {
  margin-top: 30px;
}

.image {
  max-width: 428px;
  width: 100%;
}

.gridContainer {
  display: grid;
  grid-template-columns: 2fr 2fr;
  position: relative;
}

.gridContainerSingle {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
}

.bgHover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.hoverAction {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  gap: 15px;
}

.orderProcess {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.buttonCustomMobile {
  margin: 1px;
  background: rgb(101, 127, 146);
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 8px 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1.30623px 2.61247px rgb(0 0 0 / 25%);
}
.actionItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.actionItem:hover {
  background-color: #ffe353;
}

.actionItem > span {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.actionItem:hover > span {
  color: #181f51;
}
.actionItem .iconDark {
  display: none;
}

.actionItem:hover .iconDefault {
  display: none;
}

.actionItem:hover .iconDark {
  display: block;
}

.actions {
  gap: 12px;
  flex-wrap: wrap;
}

.iconActions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.bgYellow {
  background-color: #ffe353 !important;
}
.bgYellow > span {
  color: #181f51 !important;
}

.buttonContainer {
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.buttonContainer {
  background-color: #111;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(
    1.23deg,
    #ffea97 1.06%,
    #ffdb6a 59.46%,
    #ffce4a 76.37%,
    #ffc133 87.13%,
    #ffa800 99.42%,
    #ffb320 99.42%
  );
  margin-bottom: 10px;
}

.buttonCustom {
  margin: 1px;
  background: rgb(39, 36, 24);
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 8px 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1.30623px 2.61247px rgba(0, 0, 0, 0.25);
}

.buttonCustom:hover {
  background: #626740;
}

.buttonCustomMobile:hover {
  background: rgb(177, 194, 171);
}

.yellowHover {
  background: #626740 !important;
}

.modalInfo {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 15px 23px;
  width: 168px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #ffffff;
  line-height: 16px;
}

.modalInfoContent {
  width: 100%;
  word-wrap: break-word;
  max-height: 300px;
  overflow: auto;
}

.btnCopy {
  background: #ffe353;
  border-radius: 4px;
  margin-top: 15px;
}

.btnCopy:hover {
  opacity: 0.7;
}

.iconCopy {
  display: block;
  border-radius: 50%;
  background: #181f51;
  width: 16px;
  height: 16px;
  margin-right: 12px;
}

.iconCopyMobile {
  display: none;
  margin-right: 12px;
}

.btnCopy > :last-child {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #181f51;
}

.infoClick {
  background: linear-gradient(
    1.23deg,
    rgba(255, 234, 151, 0.3) 1.06%,
    rgba(255, 219, 106, 0.3) 59.46%,
    rgba(255, 206, 74, 0.3) 76.37%,
    rgba(255, 193, 51, 0.3) 87.13%,
    rgba(255, 168, 0, 0.3) 99.42%,
    rgba(255, 179, 32, 0.3) 99.42%
  );
  border-radius: 128px;
}

.recentBtn {
  display: none;
}

.popupContainer {
  border-radius: 8px;
  position: absolute;
  left: 72px;
  bottom: 0;
}

.processMobile {
  margin-top: 36px;
}

.hoverActionMobile {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.imageKeyword {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 36px;
  color: #ffffff;
  display: none;
  max-width: 343px;
  white-space: break-work;
  word-wrap: break-word;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}

@media screen and (max-width: 600px) {
  .gridContainerSingle {
    padding-right: 43px;
    padding-left: 43px;
  }
  .iconActions {
    width: 100%;
    gap: 8px;
  }
  .actions {
    column-gap: 10px;
    row-gap: 8px;
  }
  .actionItem {
    padding: 8px;
  }

  .recentBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #ffffff;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    text-shadow: 0px 1.30623px 2.61247px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  .modalInfo {
    padding: 16px;
    background: #4b6170;
    backdrop-filter: blur(40px);
  }
  .popupContainer {
    padding: 1px;
    left: -100px;
    bottom: 52px;
    background: linear-gradient(
      1.23deg,
      #ffea97 1.06%,
      #ffdb6a 59.46%,
      #ffce4a 76.37%,
      #ffc133 87.13%,
      #ffa800 99.42%,
      #ffb320 99.42%
    );
  }
  .bgHover {
    display: none;
  }
  .yellowHover {
    background: rgb(177, 194, 171) !important;
  }
  .imageKeyword {
    display: block;
  }
  .iconCopy {
    display: none;
  }
  .iconCopyMobile {
    display: block;
  }
}

@media screen and (min-width: 599px) {
  .processMobile {
    display: none;
  }
}
