.gridContainer {
  padding: 10px;
  border-radius: 12px;
  background: #151b41;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid;
}

.singleImg {
  width: 100%;
  height: 100%;
}

.borderGradient {
  background: linear-gradient(
    1.23deg,
    #ffea97 1.06%,
    #ffdb6a 59.46%,
    #ffce4a 76.37%,
    #ffc133 87.13%,
    #ffa800 99.42%,
    #ffb320 99.42%
  );
  border-radius: 12px;
  padding: 2px;
}
