.drop-down-item {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 8px 16px;
  background-color: #111111;
  border-radius: 4px;
  &:hover {
    background: #161616;
  }
}

.text-creative-level {
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.drop-down-item-label {
  margin-right: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
