@font-face {
  font-family: clash display;
  font-style: normal;
  font-weight: 700;
  src: local("Clash Display"),
    url(./assets/font/ClashDisplayBold.woff) format("woff");
}
@font-face {
  font-family: clash display;
  font-style: normal;
  font-weight: 600;
  src: local("Clash Display"),
    url(./assets/font/ClashDisplaySemibold.woff) format("woff");
}
@font-face {
  font-family: clash display;
  font-style: normal;
  font-weight: 500;
  src: local("Clash Display"),
    url(./assets/font/ClashDisplayMedium.woff) format("woff");
}

html,
body,
#root {
  height: 100% !important;
  width: 100% !important;
}

* {
  font-family: "Poppins", sans-serif;
}

body {
  overflow: hidden;
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.member-item {
  display: flex;
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 24px;
  flex-direction: column;
}
.member-item-num {
  align-self: stretch;
  background: linear-gradient(
    0deg,
    rgba(24, 31, 81, 0.05),
    rgba(24, 31, 81, 0.05)
  );
  flex: 0 0 auto;
  padding: 16px;
  text-align: center;
  justify-content: center;
  display: flex;
}
.member-item-num > div {
  background: #00d1ff;
  border-radius: 50%;
  width: 36px;
  margin: auto;
  color: rgb(255, 255, 255);
  height: 36px;
  font-weight: 600;
  line-height: 1.5;
  font-size: 24px;
}
.member-item-info {
  display: flex;
  padding: 24px;
  flex-grow: 1;
  flex-direction: column;
}
.member-item-see-more {
  margin: 15px auto 0;
}

@media screen and (min-width: 852px) {
  .member-item {
    -moz-box-align: center;
    align-items: center;
    flex-direction: row;
  }
  .member-item-info {
    flex-direction: row;
  }
  .member-item-see-more {
    margin: auto;
  }
}
