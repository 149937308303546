.container {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.center {
  align-items: center;
}

.container-content {
  background-image: url("../assets/images/background.webp");
  flex-direction: column;
  display: flex;
  background-size: cover;
  flex: auto;
  padding-top: 9.722222vh;
  width: 100%;
}

.title {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
  background: linear-gradient(
    90.8deg,
    #ffea97 0%,
    #ffdb6a 59.3%,
    #ffce4a 76.46%,
    #ffc133 87.38%,
    #ffa800 99.87%,
    #ffb320 99.87%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
}

.input-view {
  display: flex;
  margin-top: 4.722222vh;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.input-action {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  width: 100%;
  background-color: rgba(255, 227, 83, 0.2);
  max-width: 601px;
}

.other-action {
  display: flex;
}

.input-value {
  flex: 1;
  height: inherit;
  border-radius: 8px 0px 0px 8px;
  background: transparent;
  padding: 0 14px;
  color: #ffffff;
  font-size: 16px;
  line-height: 22.6px;
  font-weight: 600;
  font-family: "Poppins";
  border-width: 0;
  text-overflow: ellipsis;
  max-width: 494px;
  height: 54px;
  width: 100%;
}

.input-border-loading {
  background: linear-gradient(
    90.8deg,
    #ffea97 0%,
    #ffdb6a 59.3%,
    #ffce4a 76.46%,
    #ffc133 87.38%,
    #ffa800 99.87%,
    #ffb320 99.87%
  );
  padding: 1px;

  & > .input-value {
    background: #4c4d57;
  }

  & > .cloud-input.textarea {
    background: #4c4d57;
  }
}

.input-value:focus {
  outline: none;
}

.input-value::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffe353;
  opacity: 1;
  /* Firefox */
}

.input-value:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffe353;
}

.input-value::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffe353;
}

input:disabled {
  cursor: default;
}

.btn-generate {
  display: flex;
  height: inherit;
  background-image: linear-gradient(
    1.23deg,
    #ffea97 1.06%,
    #ffdb6a 59.46%,
    #ffce4a 76.37%,
    #ffc133 87.13%,
    #ffa800 99.42%,
    #ffb320 99.42%
  );
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 0 16px;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;

  &:hover {
    background: #ffea97;
    opacity: 0.5;
  }

  width: 107px;
  box-sizing: border-box;
  align-items: center;
}

.dropdown {
  position: relative;
  align-self: center;

  > .outer-list {
    position: absolute;
    z-index: 1;
    top: 34px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.top {
      align-items: flex-start;
    }

    > ul {
      list-style: none;
      padding: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #222222;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
      border-radius: 0.2em;
      margin-top: 0px;
      margin-bottom: 0px;
      row-gap: 4px;

      li {
        width: 220px;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.07);
        }
      }
    }
  }
}

.drop-down {
  height: 30px;
  padding: 0 16px;
  align-self: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.drop-down-border-gradient {
  background: linear-gradient(
    1.23deg,
    #ffea97 1.06%,
    #ffdb6a 59.46%,
    #ffce4a 76.37%,
    #ffc133 87.13%,
    #ffa800 99.42%,
    #ffb320 99.42%
  );
  border-radius: 4px;

  &:hover {
    padding: 1px;
  }
}

.p-8-16 {
  padding: 8px 16px;
}

.w-full {
  width: 100%;
}

.d-none {
  display: none !important;
}

.information-container {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  width: 425px;
  height: 80px;
}

.modal-information-style-custom {
  top: 278px !important;
  transform: translate(0%, 0%) !important;
  left: 45% !important;
}

.border-logo-1 {
  background: rgba(255, 181, 36, 0.4);
  width: 337px;
  height: 337px;
  border-radius: 50%;
  position: absolute;
  padding: 0;
  margin: 0;
}

.border-logo-2 {
  background: rgba(255, 181, 36, 0.4);
  width: 283px;
  height: 283px;
  border-radius: 50%;
  position: absolute;
  padding: 0;
  margin: 0;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-bar {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(
      180deg,
      #181f51 0%,
      #111745 20.31%,
      #0c103a 43.23%,
      #070a2e 66.67%,
      #040726 100%
    ),
    #ffffff;
  width: 180px;
  padding: 20px 11px;
  flex: auto 0 0;
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
}

.right-bar > :not(:first-child) {
  margin-top: 30px;
}

.recent-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  text-align: center;
}

.c-not-allow {
  cursor: not-allowed !important;
}

.disabled {
  cursor: default;
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 32px;
    line-height: 39px;
  }

  .container-content {
    padding-left: 15px;
    box-sizing: border-box;
  }

  .input-action {
    min-height: 49px;
  }

  //.input-view{
  //  margin-top: 0;
  //}

  .input-value {
    font-size: 12px;
    line-height: 140%;
    padding: 0 0 0 14px;
  }

  .btn-generate {
    font-size: 12px;
    line-height: 140%;
    width: 89px;
  }

  .input-view {
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
  }

  .right-bar {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(40px);
    display: none;
  }

  .border-logo-1 {
    width: 180px;
    height: 180px;
  }

  .border-logo-2 {
    width: 150px;
    height: 150px;
  }

  .modal-information-style-custom {
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 90vw;
    margin: 0 auto;
  }

  .information-container {
    width: 100%;
    height: 100%;
  }
}

.cloud-loading-container {
  animation: fadeIn 1s ease-in-out;
  position: absolute;
  z-index: 40;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  margin: 0;
  padding: 0;
}

.generate-progress-bar {
  height: 1rem;
  position: absolute;
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 522px;
  bottom: 10.625rem;
  z-index: 20;
}

.progress-bar-percent {
  position: absolute;
  width: 0%;
  height: 100%;
  background: linear-gradient(
    1.23deg,
    #ffea97 1.06%,
    #ffdb6a 59.46%,
    #ffce4a 76.37%,
    #ffc133 87.13%,
    #ffa800 99.42%,
    #ffb320 99.42%
  );
  transition: all 0.1s ease-in;

  .percent-number {
    background: rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
    color: #fafafa;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    bottom: calc(100% + 12px);
    right: -8px;
    padding: 2px 12px;
    position: absolute;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.cloud-input.textarea {
  resize: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #fff;
  padding: 16px;
  border-radius: 8px 0px 0px 8px;

  & > .text-content {
    overflow: auto;
    max-height: 120px;
    padding-right: 8px;
    word-break: break-word;
  }
}

.text-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  display: block !important;
}

.text-content::-webkit-scrollbar-thumb {
  background: linear-gradient(
    90.8deg,
    #ffea97 0%,
    #ffdb6a 59.3%,
    #ffce4a 76.46%,
    #ffc133 87.38%,
    #ffa800 99.87%,
    #ffb320 99.87%
  );
  border-radius: 12px;
}

.overlay-recent {
  position: absolute;
  inset: 0;
}

.overlay-recent--left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100vw - 180px);
}
